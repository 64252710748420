<template>
    <div class="category-inventory-view">
        <div class="category-inventory-header">
            <span class="category-inventory-title">品类库存</span>
            <i class="el-icon-close" @click="closeWindow"></i>
        </div>
        <div class="category-inventory-body">
            <div class="search-box">
                <span class="search-label">款式分类</span>
                <el-cascader
                    :options="categoryList"
                    :show-all-levels="true"
                    v-model="typeId"
                    filterable
                    collapse-tags
                    clearable
                    size="mini"
                    :props="{
                        label: 'categoryName',
                        value: 'id',
                        children: 'child',
                        multiple: false,
                        checkStrictly: true
                    }"
                    @change="searchList()"
                ></el-cascader>
            </div>
            <div class="table-box">
                <el-table
                    ref="tableIns"
                    :data="tableList"
                    border
                    height="692px"
                    size="medium"
                    align="center"
                    v-loading="loading"
                    :highlight-current-row="false"
                >
                    <el-table-column prop="category1" label="一级分类" :show-overflow-tooltip="true"> </el-table-column>
                    <el-table-column prop="category2" label="二级分类" :show-overflow-tooltip="true"> </el-table-column>
                    <el-table-column prop="usableQuantity" label="可用库存" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="occupiedQuantity" label="占用库存" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="notSentQuantity" label="已配未发" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="intransitQuantity" label="在途库存" :show-overflow-tooltip="true">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { erp2MarketingUnitStockQueryCategoryCount } from '@/libs/http/modules/erp'
import { queryAllByType } from '@/libs/http/api'
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";
export default {
    name: 'categoryInventory',
    components: {},
    data() {
        return {
            loading: false,
            dialogVisible: true,
            tableList: [],
            categoryList: [],
            typeId: []
        }
    },
    computed: {},
    created() {
        this.getCategoryOrStyle()
    },
    methods: {
        closeWindow() {
            this.$confirm('提示', {
                title: '提示',
                message: '此操作将会关闭当前窗口，是否确认关闭？',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then((res) => {
					ipc.invoke(ipcApiRoute.closeWindow, 'categoryInventory').then(r => {})
                })
                .catch((err) => {
                    console.log(`用户点击取消${err}`)
                })
        },
        show() {
            this.dialogVisible = true
        },
        searchList() {
            if (this.typeId && this.typeId.length) {
                let reqData = {
                    categoryInfoId: this.typeId[this.typeId.length - 1],
                    categoryLevel: this.typeId.length
                }
                this.loading = true
                erp2MarketingUnitStockQueryCategoryCount(reqData)
                    .then((data) => {
                        this.tableList = data
                        this.loading = false
                    })
                    .catch((error) => {
                        this.$message.error(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },

        //查询 款式
        getCategoryOrStyle(type = 0) {
            this.loading = true
            queryAllByType({ type })
                .then((data) => {
                    console.log(data)
                    this.categoryList =
                        data.map((item) => ({
                            categoryName: item.categoryName,
                            id: item.id,
                            child: item.child
                                ? item.child.map((i) => ({
                                      categoryName: i.categoryName,
                                      id: i.id
                                  }))
                                : []
                        })) || []
                })
                .catch((error) => {
                    this.$message.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss">
.category-inventory-view {
    height: 100%;
    border: 1px solid #ddd;
    .category-inventory-header {
        background: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > i {
            color: #979aa0;
            cursor: pointer;
        }
        border-bottom: 1px solid #eee;
    }
    .category-inventory-title {
        padding-left: 10px;
        border-left: 3px solid #077cf2;
    }

    .category-inventory-body {
        padding: 10px;
    }
    .search-box {
        padding-bottom: 10px;
        .search-label {
            margin-right: 10px;
            font-size: 14px;
            width: 90px;
        }
    }
    .table-box {
        .el-table--medium .el-table__cell {
            padding: 0 !important;
            height: 23px;
        }
        .el-table-column--selection .cell {
            padding-right: 10px;
        }
        .el-table__header-wrapper {
            th.el-table__cell {
                background-color: #e8e8e8;
                color: #111;
            }
        }
    }
}
</style>
