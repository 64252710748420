import request from './index.js'

export const uploadResource = '/common/upload-resource' //资源上传

export const querySaleDepts = (params) => request.post('/user/query-sale-depts', params) //销售部门架构信息
export const queryCityInfoList = (params) => request.post('/erp/city/query-city-info-list', params) //获取城市列表
export const Login = (params) => request.post('/user/login', params) //用户登录
export const getOauthScan = (params) => request.post('/common/get-oauth-scan-identify', params) //获取扫码登录信息(二维码)
export const oauthScanPreLogin = (params) => request.post('/common/oauth-scan-pre-login', params) //扫码登录被扫端登录前状态查询
export const appUpdate = (params) => request.postC('/common/app-update', params) //app检查更新
export const currentVersion = (params) => request.postC('/common/current-version-info', params) //查询当前版本信息
export const queryPluginInfo = (params) => request.post('/common/print/query-plugin-info', params) //查询插件列表
export const salesQueryGrade = (params) => request.post('/pos/summary/query-dept-sell-statistics-info', params) //查询店铺业绩信息
export const queryDeptUserSales = (params) =>
    request.post('/pos/summary/query-user-sell-statistics-info-by-dept', params) //按店铺查询用户业绩信息列表
export const NewsListQuery = (params) => request.post('/news/query-list', params) //新闻列表查询
export const submitBrowseRecord = (params) => request.post('/news/submit-browse-record', params) //新闻列表查询
export const queryGoodsCategoryTree = (params) => request.post('/erp/query-goods-category-info-menu-tree', params) //查询分类信息树形菜单  款式风格时间分类
export const queryBarcodeVersion = (params) => request.post('/erp/query-erp-bar-code-off-line-version', params) //查询最新一条条码离线版本信息

// 流程步骤相关
export const queryTypeFlow = (params) => request.post('/user/flow/query-type-flow', params) //查询定义流程的完整步骤
export const submitApproval = (params) => request.post('/user/flow/submit-review-info', params) //发起退换货流程
export const queryInvoicesInfoOms = (params) => request.post('/user/flow/query-review-info', params) //查询收银审批详情
export const queryErpLogisticsTrack = (params) =>
    request.post('/oms/erp-visitor-booking/query-erp-logistics-track-info-list', params) //查询物流轨迹
// 工单管理
export const reimbursePerson = (params) => request.post('/wos/query-wos-deal-with-person', params) //查询控制台工单信息
export const wosQueryType = (params) => request.post('/wos/query-type-list', params) //查询工单类型列表
export const reimburse = (params) => request.post('/wos/query-wos-question-list', params) //查询问题列表
export const submitWos = (params) => request.post('/wos/submit-wos', params) //提交工单
export const detailInfo = (params) => request.post('/wos/query-wos-detail-info', params) //查看工单详情
export const dealWos = (params) => request.post('/wos/deal-wos', params) //处理工单
export const userOperate = (params) => request.post('/college/user-operate', params) //点赞点踩收藏
export const complaint = (params) => request.post('/wos/query-can-complaint', params) //查询工单中可以投诉的人

// 店铺排班
export const queryGroupClassInfo = (params) => request.post('/user/hr/query-check-group-class-info', params) //查询考勤组信息
export const saveWeekClassInfo = (params) => request.post('/user/hr/save-week-class-info', params) //保存周期排班
export const queryWeekClassInfo = (params) => request.post('/user/hr/query-week-class-info', params) //查询周期班次列表
export const submitSetClassInfo = (params) => request.post('/user/hr/submit-set-class-info', params) //提交排班信息
export const submitClassByController = (params) => request.post('/user/hr/submit-class-by-controller', params) //控制台提交排班
export const queryCurrentMonthClass = (params) => request.post('/user/hr/query-current-month-class', params) //控制台提交排班
export const updateTrtcState = (params) => request.post('/news/call/update-dept-trtc-state', params) //客户端上线/下线

//业绩日历
export const queryUserSellStatis = (params) =>
    request.post('/pos/summary/query-user-and-dept-sell-statistics-info-by-dept', params) //按店铺查询用户业绩信息列表
export const queryMonthSellStatis = (params) => request.post('/pos/summary/query-month-sell-statistics-info', params) //按店铺查询用户业绩信息列表
export const queryTimeQuantum = (params) => request.post('/pos/summary/query-day-amount-list', params) //时间段业绩

// ERP相关接口
export const queryMarketing = (params) => request.post('/erp/erp-marketing-unit-stock/query-stock-list', params) //销售单位库存结存查询
export const qyueryInvoiceList = (params) => request.post('/erp/query-invoices-info-list', params) //查询单据信息列表
export const queryIntoStockPackageList = (params) => request.post('/erp/package/query-into-stock-package-list', params) //查询单据信息列表
export const erpQueryCommodity = (params) => request.post('/erp/query-commodity-invoices-info', params) //查询商品单据详情
export const erpQuerySizeInfo = (params) => request.post('/erp2/operating/query-size-group-info-list', params) //查询尺码组信息列表
export const queryConsoleIntoStockDetail = (params) => request.post('/erp/query-console-into-stock-detail', params) //控制台-开始验收
export const erpQueryIntoStockPackageSkuConsole = (params) =>
    request.post('/erp/package/query-into-stock-package-sku-console', params) //根据包裹号查验收信息
export const queryIntoStockPackageSku = (params) => request.post('/erp/package/query-into-stock-package-sku', params) //控制台-开始验收--(包裹号)
export const supplierInfoQueryList = (params) => request.post('/erp/erp-supplier-info/query-list', params) //查询供应商列表
export const erpCheckpackageGoods = (params) => request.post('/erp/package/check-goods', params) //入库（验收）--包裹
export const erpCheckGoods = (params) => request.post('/erp/check-goods', params) //入库（验收）
export const erpPackageCompleteCheck = (params) => request.post('/erp/package/complete-check', params) //完成入库（验收）
export const erpCompleteCheck = (params) => request.post('/erp/complete-check', params) //完成入库（验收）
export const queryWarehouseUnitInfoList = (params) => request.post('/erp/query-warehouse-unit-info-list', params) //查询仓库信息列表
export const saveInvoicesExpressNo = (params) => request.post('/erp/save-invoices-express-no', params) //修改/录入单据快递单号
export const queryPackSkuInfoList = (params) => request.post('/erp/query-pack-sku-info-list', params) //查询包裹里的货品信息列表
export const erpPackageSaveInvoicesExpressNo = (params) => request.post('/erp/package/save-invoices-express-no', params) //新查询包裹里的货品信息列表
export const erpQueryGoodsInfo = (params) => request.post('/erp/query-goods-size-inventory-info-list', params) //查询货品相关尺码库存信息列表
export const queryCommodityInvoicesInfo = (params) => request.post('/erp/query-commodity-invoices-info', params) //查询商品单据详情
export const queryConsoleOutStockDetail = (params) => request.post('/erp/query-console-out-stock-detail', params) //控制台-开始拣货
export const erpPickGoods = (params) => request.post('/erp/pick-goods', params) //出库（拣货）
export const completePick = (params) => request.post('/erp/complete-pick', params) //出库（拣货）
export const erpSubmitInvoice = (params) => request.post('/erp/submit-invoices-info', params) //提交单据信息
export const erpQueryUnitGoods = (params) => request.post('/erp/query-unit-goods-info-list', params) //查询单位下的货品可用库存信息列表（销售单位、仓库单位）
export const queryOnceAddMax = (params) => request.post('/erp/erp-global-config/list', params) //查询基础维护
export const queryInvoicesLogistic = (params) => request.post('/erp/query-invoices-logistic-info-list', params) //查询物流跟踪消息
export const logisticsCompanyList = (params) => request.post('/erp/erp-logistics-company/list', params) // 物流公司信息列表查询
export const queryMonthlyAccountInfoByLogistics = (params) =>
    request.post('/erp/erp-monthly-account-info/query-monthly-account-info-by-logistics', params) // 月结账号信息查询
export const erpQueryMarketingDeptMenuTree = (params) => request.post('/erp/query-marketing-dept-menu-tree', params) //查询销售单位对应的部门级联信息
export const erpQueryInvoicesInfo = (params) => request.post('/erp/query-invoices-goods-info', params) //查询单据信息及单据下的货品信息
export const erpDeleteCancellation = (params) => request.post('/erp/batch-delete-or-cancellation-invoices-info', params) //删除/作废铺货单据信息
export const erpPackageBeforeCallJdExpress = (params) => request.post('/erp/package/before-call-jd-express', params) //京东预备下单
export const erpPackageCallJdExpress = (params) => request.post('/erp/package/call-jd-express', params) //京东下单
export const erpPackageRecallJdExpress = (params) => request.post('/erp/package/recall-jd-express', params) //取消京东下单
export const queryIsAllowNewTransferOrder = (params) =>
    request.post('/erp/erp-marketing-unit-info/query-is-allow-new-transfer-order', params) //使用拥有新建调拨单权限
export const erpAdministratorsSubmitInvoicesAuthority = (params) =>
    request.post('/erp/administrators/submit-invoices-authority', params) //判断当前登陆人是直营还是加盟
export const negativeStockAuthority = (params) => request.post('/erp/administrators/negative-stock-authority', params) //查询店铺是否允许负库存
export const queryAllByType = (params) => request.post('/erp/erp-goods-category-info/query-all-by-type', params) //商品分类信息根据类型查询  款式风格时间分类

//店铺补货申请
export const erp2MarketReplenishment = (params) =>
    request.post('/erp2/market-replenishment/query-unit-goods-info-list', params) //新增页面内获取单据货号详情
export const erp2MarketQueryUnitGoodsSize = (params) =>
    request.post('/erp2/market-replenishment/query-unit-goods-size-info', params) //新增页面内获取货号尺寸数据
export const erp2MarketReplenishmentSubmit = (params) => request.post('/erp2/market-replenishment/submit-info', params) //提交
export const erp2MarketReplenishmentQueryList = (params) =>
    request.post('/erp2/market-replenishment/query-list', params) //列表
export const erp2MarketReplenishmentQueryDetail = (params) =>
    request.post('/erp2/market-replenishment/query-detail', params) //详情

export const printTemplateUrl = (params) => request.post('/print/template/query-plugin-print-template-info-url', params) //打印模板链接
export const queryPeriodFollow = (params) => request.post('/user/query-store-period-target-follow-table-list', params) //时段目标跟进查询
export const updatePeriodFollow = (params) => request.post('/user/update-store-period-target-follow-table', params) //更新时段目标跟进

export const getHrAttendanceQrCode = (params) => request.post('/user/get-hr-attendance-qr-code', params)//获取打卡二维码
export const getHrAttendanceQrCodeList = (params) => request.post('/user/get-hr-attendance-qr-code-list', params)//新获取打卡二维码
