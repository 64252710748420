var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-inventory-view"},[_c('div',{staticClass:"category-inventory-header"},[_c('span',{staticClass:"category-inventory-title"},[_vm._v("品类库存")]),_c('i',{staticClass:"el-icon-close",on:{"click":_vm.closeWindow}})]),_c('div',{staticClass:"category-inventory-body"},[_c('div',{staticClass:"search-box"},[_c('span',{staticClass:"search-label"},[_vm._v("款式分类")]),_c('el-cascader',{attrs:{"options":_vm.categoryList,"show-all-levels":true,"filterable":"","collapse-tags":"","clearable":"","size":"mini","props":{
                    label: 'categoryName',
                    value: 'id',
                    children: 'child',
                    multiple: false,
                    checkStrictly: true
                }},on:{"change":function($event){return _vm.searchList()}},model:{value:(_vm.typeId),callback:function ($$v) {_vm.typeId=$$v},expression:"typeId"}})],1),_c('div',{staticClass:"table-box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"tableIns",attrs:{"data":_vm.tableList,"border":"","height":"692px","size":"medium","align":"center","highlight-current-row":false}},[_c('el-table-column',{attrs:{"prop":"category1","label":"一级分类","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"category2","label":"二级分类","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"usableQuantity","label":"可用库存","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"occupiedQuantity","label":"占用库存","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"notSentQuantity","label":"已配未发","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"intransitQuantity","label":"在途库存","show-overflow-tooltip":true}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }